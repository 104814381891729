import { useEffect } from "react";
import "./Home.css";
import Carousel from "react-material-ui-carousel";
import bg from "../../Assets/background.jpg";
import bg2 from "../../Assets/background2.jpg";
import ProductCard from "../Product/ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { getProduct } from "../../actions/productAction";
import Header from "./Header";
import MetaData from "../../more/Metadata";
import Footer from "../../more/Footer";
import BottomTab from "../../more/BottomTab";
import Loading from "../../more/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { clearProductErrors } from "../../redux/product/productReducer";

const Home = () => {
  const dispatch = useDispatch();
  const { products, error } = useSelector((state) => state.products);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearProductErrors());
    }
    dispatch(getProduct());
  }, [dispatch, error]);

  return (
    <>
      <MetaData title="Home" />
      <Header />
      {/* Carousel */}
      <div className="banner">
        <Carousel>
          <img alt="" src={bg} className="bgImg" />
          <img alt="" src={bg2} className="bgImg" />
        </Carousel>
        <div className="home__content">
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <h2
              style={{
                fontFamily: "Segoe Script",
                fontSize: "3em",
                fontWeight: "500",
              }}
            >
              Buy 2 Get
            </h2>
            <span
              style={{
                padding: "10px",
                backgroundColor: "#fff",
                margin: "0px 10px",
                textAlign: "center",
                width: "150px",
                height: "40px",
                color: "#26c",
                fontFamily: "Segoe Script",
                fontSize: "2.4em",
                display: "flex",
                justifyContent: "center",
                lineHeight: ".7",
                alignItems: "center",
              }}
            >
              1 Free
            </span>
          </div>
          <div>
            <h2
              style={{
                fontSize: "4.5em",
                fontFamily: "Poppins,sans-serif",
                color: "#fff",
              }}
            >
              Fashionable
            </h2>
          </div>
          <div>
            <h2
              style={{
                fontSize: "4.5em",
                fontWeight: "400",
                fontFamily: "Poppins,sans-serif",
                color: "#fff",
                lineHeight: ".7",
              }}
            >
              Collection
            </h2>
          </div>
          <div>
            <h2
              style={{
                fontWeight: "400",
                fontFamily: "Poppins,sans-serif",
                color: "#fff",
                fontSize: "1em",
                paddingTop: "10px",
              }}
            >
              Get Free Shipping on all orders over $99.00
            </h2>
          </div>
          <div>
            <a href="#container">
              <button
                type="submit"
                style={{
                  height: "50px",
                  border: "none",
                  background: "#3BB77E",
                  margin: "10px 0",
                  fontSize: "1.2vmax",
                  color: "#fff",
                  cursor: "pointer",
                }}
                className="Home__button"
              >
                SHOP NOW
              </button>
            </a>
          </div>
        </div>
      </div>

      <h2 className="homeHeading">Featured Products</h2>
      <div className="container" id="container">
        {products &&
          products.map((product) => (
            <ProductCard key={product._id} product={product} />
          ))}
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer />
      <BottomTab />
    </>
  );
};

export default Home;
